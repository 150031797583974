aside {
  margin: 20px 0;
  line-height: 34px;
  position: relative;
  cursor: pointer;
  user-select: none;
}

aside:hover {
  top: -1px;
}

aside:active {
  top: 1px;
}

aside.arrow_in {
  background-color: #cecece;
  height: 60px;
  font-size: 22px;
  line-height: 56px;
  padding-left: 20px;
  padding-right: 20px;
}

aside.arrow_in:after,
aside.arrow_in:before {
  border-width: 30px 0 30px 30px;
}

aside.arrow_in:before {
  border-color: #cecece transparent;
  left: -29px;
}

aside.arrow_in:after {
  border-color: transparent #cecece;
  right: -29px;
}

aside.arrow {
  background-color: #5995ef;
  height: 60px;
  font-size: 22px;
  line-height: 56px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
}

aside.arrow:after,
aside.arrow:before {
  border-width: 30px 0 30px 30px;
}

aside.arrow:before {
  border-color: #5995ef transparent;
  left: -29px;
}

aside.arrow:after {
  border-color: transparent #5995ef;
  right: -29px;
}

aside:before,
aside:after {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 0;
}

aside.sheer {
  background-color: #85C9ED;
  left: -1px;
  color: #3e3838;
    position: absolute;
    top: 0;
    font-size: 16px;
    font-weight: bold;
    min-width: 60%;
}
aside.sheer:after, aside.sheer:before {
  border-width: 33px 10px 0 0;
}
aside.sheer:before {
  left: -10px;
  border-color: transparent #85C9ED;
}
aside.sheer:after {
  right: -10px;
  border-color: #85C9ED transparent;
}