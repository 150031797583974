.avatar {
    width: 150px;
    height: 150px;
    border-radius: 150px;
}
.fname {
    color: #0a0a0a;
    font-size: 30px;
    margin: 0;
}
.lname {
    color: #0a0a0a;
    font-size: 30px;
    font-weight: 600;
    margin: 0;
}
.borderW {
    width: 140px;
    height: 8px;
    background-color: black;
    margin-top: 10px;
    margin-bottom: 20px;
}
.f12 {
    font-size: 12px;
}
h5 {
    font-size: 18px;
    font-weight: 600;
    color: #000;
}
.sm-title {
    font-weight: bold;
    color: #000;
}
td {
    color: #000;
}

